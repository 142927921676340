import { Directive } from '@angular/core';
import { UserMenuComponent } from './user-menu.component';

@Directive({
  selector: '[routerLink]',
  host: {
    '(click)': 'onClick($event)',
  },
})
export class UserMenuRouterLinkDirective {
  constructor(private userMenu: UserMenuComponent) {}
  onClick(ev: Event) {
    if (this.userMenu.closeOnNavigation) this.userMenu.closeShell();
  }
}
